import React from 'react'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import Helmet from 'react-helmet'
import useSiteMetadata from '../hooks/use-site-metadata'
import { createHistory } from '@reach/router'

const SearchPage = ({ uri }) => {
  const search =
    typeof window !== 'undefined' ? createHistory(window).location.search : ''
  const query = decodeURI(search.slice(3))
  const hasSearch = search.includes('?q=')
  const { url: siteUrl, title: siteTitle } = useSiteMetadata()

  return (
    <Layout>
      <Helmet title={`${query} | ${siteTitle}`}>
        <link rel="canonical" href={`${siteUrl}${uri}/?q=${query}`} />
      </Helmet>
      <PostList
        title="SEARCH RESULTS"
        type="search"
        hasSearch={hasSearch}
        query={query}
      />
    </Layout>
  )
}

export default SearchPage
